import React from 'react'
import { graphql } from 'gatsby';
// import Img from 'gatsby-image'
import Layout from '../components/layout'
import { Trail, animated } from 'react-spring'
import Helmet from 'react-helmet'
import socialImage from '../images/Team_Page_Social_Media_Thumb_01.jpg'
import '../assets/team.scss'


const team = ({data}) => {
	const allBios = [
		{
			'name': 'Martin Rodahl',
			'pic' : data.martinImage.childImageSharp.fluid.src,
			'title': 'Executive Producer',
			'bio': 'Martin founded Picture North in 2008 and has been producing content under its banner ever since. In the independent feature film world, Martin\'s projects have screened at such festivals as Cannes, SXSW, and Tribeca. His forays into television have landed Picture North two shows, one with NBC/Universal and the other on Discovery Network.'
		},
		{
			'name': 'Neha Schultz',
			'pic' : data.nehaImage.childImageSharp.fluid.src,
			'title': 'Executive Producer and Head of Development',
			'bio': 'Neha joined Picture North in 2014 as Executive Producer after a successful career at Harpo Studios and CAA. Her role involves overseeing operations, cultivating and developing our director roster, and generating new business across all platforms. With a penchant for travel, culture, language and food, she and Martin often race to see who can log more airline miles.'
		},
		{
			'name': 'Ryan Patrick',
			'pic' : data.ryanImage.childImageSharp.fluid.src,
			'title': 'Executive Producer',
			'bio': 'Based in LA, Ryan joined Picture North in 2020 as both a director and an EP. With a keen eye for talent, Ryan loves developing directors and celebrating great work just as much as he loves getting behind the camera. A two-time nominee of the Cannes YDA, Ryan\'s spots and films have screened on Vimeo Staff Picks, at SXSW, and at numerous other festivals. When not directing or producing, Ryan is usually trying to run a marathon.'
		},
		{
			'name': 'Michael Nowicki',
			'pic' : data.mikeImage.childImageSharp.fluid.src,
			'title': 'Head of Operations',
			'bio': 'Michael brought his skills to Picture North in 2016 after a decade of work as an independent producer, director and editor in commercials, music videos and documentaries. He’s a self-described autodidact with an appetite for adventure. If you happen to run into him outside of work, you might catch him either scaling up a mountain or snowboarding down it.'
		},
		{
			'name': 'Matt Bobbitt',
			'pic' : data.mattImage.childImageSharp.fluid.src,
			'title': 'Head of Production',
			'bio': 'Joining Picture North in 2021 as Head of Production, Matt brings a passion for brand-centric content that is matched only by his drive and pursuit for new life challenges.  When Matt is not immersed in a project, you can find him adventuring with his partner and their pupper, Butters, always looking forward to the next thrill, the next exploration, and the next hammock in the shade.'
		},
		{
			'name': 'Evan Staley',
			'pic' : data.evanImage.childImageSharp.fluid.src,
			'title': 'Marketing Manager',
			'bio': 'After excelling as a company intern, Evan formally teamed up with Picture North in 2018. Starting with social media, he helped build the marketing arm and currently oversees its operations. Evan is passionate about all things outdoors. If you stumble on someone in the wilderness donning a Picture North hat, eating a PB&J, and playing the mandolin, it’s probably him.'
		},
		{
			'name': 'Stacey Nuzbach',
			'pic' : data.staceyImage.childImageSharp.fluid.src,
			'title': 'Post-Production Supervisor',
			'bio': 'Stacey\'s love for storytelling has led her to a seasoned foundation in commercial production, experiential marketing, and digital media. Both passionate and organized, she builds a trusted bond with crews and clients from the start. As Post-Production Supervisor, she helps keep the team focused and energized for every project that comes through the door.'
		},
		{
			'name': 'Jim Moore',
			'pic' : data.jimImage.childImageSharp.fluid.src,
			'title': 'Head of Finance',
			'bio': 'Whether a small tabletop shoot, or a major commercial production, Jim has always prided himself in uniting finance and production within advertising. His career began as a Production Accountant, then quickly dove headfirst into Finance and Business Affairs within digital media and creative agencies. Now, Jim utilizes his skills to provide meaningful financial support at Picture North. When he\'s not watching hockey, playing hockey, or struggling through a run, you can find Jim on a golf course, where he\'s most likely struggling there too.'
		},
	]
	const reps = [
		{
			'name' : 'VLM',
			'pic' : data.vlmImage.childImageSharp.fluid.src,
			'colorPic' : data.vlmColorImage.childImageSharp.fluid.src,
			'bio' : 'Veronica Lombardo Management (VLM) was opened with the vision of creating a roster of creative companies whose skill sets would be highly specialized and whose work would speak to a more targeted audience. VLM is the home where agencies, clients, networks and creatives come to find the obscure, the niché, the eclectic, the talented.'
		},
		{
			'name' : 'Sonia Reps',
			'pic' : data.soniaImage.childImageSharp.fluid.src,
			'colorPic' : data.soniaColorImage.childImageSharp.fluid.src,
			'bio' : 'The Sonia Reps team is led by Sonia Blum, whose years on the representation side, as an agency Account Director, along with her experience as Director of Business Development on the production side give her the experience and expertise to navigate the representation world.'
		},
		{
			'name' : 'Hapa',
			'pic' : data.hapaImage.childImageSharp.fluid.src,
			'colorPic' : data.hapaColorImage.childImageSharp.fluid.src,
			'bio' : 'Amanda Lemieux and Brandon Pico are Hapa. With offices in NY and RI, Hapa\'s roster consists of a diverse array of nimble, eclectic and inspiring production companies. Working with agency and brand direct, Hapa scours the eastern seaboard finding engaging opportunities for their teams.'
		}
	]
	
	return (
			<Layout>
				<Helmet>
					<title>Picture North | Leadership Team</title>
					<meta name="description" content="Multitalented production team delivering the bestcurated content from around the world." />
					<meta property="og:title" content='Picture North | Leadership Team' />
					<meta property="og:description" content='Multitalented production team delivering the best curated content from around the world.' />
					<meta property="og:image" content={data.site.siteMetadata.siteUrl+socialImage} />
					<meta name="twitter:card" content="summary_large_image" />
					<meta
					name="twitter:creator"
					content={'picture_north'}
					/>
					<meta name="twitter:title" content='Picture North | Leadership Team' />
					<meta name="twitter:description" content='Multitalented production team delivering the best curated content from around the world.' />
					<meta name="twitter:image" content={data.site.siteMetadata.siteUrl+socialImage} />
				</Helmet>
				<div className="header">
					<div className="row text-center">
						<div className="col">
							<h1>Team</h1>
						</div>
					</div>
				</div>
				<div className="pn-12-grid">
					<div className='row no-gutters team-list'>
						<Trail
							native
							from={{opacity:0, y:-50 }}
							to={{ opacity: 1, y: 0 }}
							keys={allBios.map( item => item.name)}
						>
						{allBios.map( (item, i) => ({y, opacity }) => {
							const colClass ='col-md-6 col-lg-3';
							return (
								<animated.div
									className={`${colClass} single-team`}
									style={{
										opacity,
									}}

								>
									<div>
										<div className="img-wrap">
											<div data-team={item.name} className='team-member' style={{backgroundImage: `url(${item.pic})`}} >
												<img className='mobile-profile'
													alt={item.name}
													src={item.pic}
												/>
												<div className='member-info'>
													<h2>{item.name}</h2>
													<div className="description-wrap">
														<div className="description">
															<span>{item.bio}</span>
														</div>
													</div>
													<h4>{item.title}</h4>
													<p className='mobile-description'>{item.bio}</p>
												</div>
											</div>
										</div>
									</div>
								</animated.div>
							)
						}
						
						)}
						</Trail>
			
					</div>

				</div>
				<div className="row no-gutters ">
					<div className="col-12 header text-center">
						<h2>Representation</h2>
					</div>
				</div>
				<div className="row no-gutters rep-list">
					<Trail
						native
						from={{opacity:0, y:-50 }}
						to={{ opacity: 1, y: 0 }}
						keys={allBios.map( item => item.name)}
					>
					{reps.map( item => ({y, opacity }) => (
						<animated.div
							className='col-md-6 col-lg-4'
							style={{
								opacity,
							}}
						>
								<div data-team={item.name} className='team-rep'  >
									<div className="rep-image">
										<img className='image-fluid color'
											alt={item.name}
											src={item.colorPic}
										/>
										<img className='image-fluid mono'
											alt={item.name}
											src={item.pic}
										/>
									</div>
									<div className="description">
										<span>{item.bio}</span>
									</div>
								</div>
								<div className='member-info'>
									<p className='mobile-description'>{item.bio}</p>
								</div>
						</animated.div>
					))}
					</Trail>
				</div>							
			</Layout>
		
		)
	} 
	
export default team

export const teamQuery = graphql`
	query teamImages {
		site {
			siteMetadata {
					siteUrl
			}
		}
		martinImage: file(absolutePath: {regex: "/Martin_Headshot_190312-2.jpg/"}) {
			childImageSharp {
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		nehaImage: file(absolutePath: {regex: "/Neha_Profile_180920.jpg/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		mikeImage: file(absolutePath: {regex: "/Michael_Profile_Vertical.jpg/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		evanImage: file(absolutePath: {regex: "/Evan.jpg/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		oliviaImage: file(absolutePath: {regex: "/OliviaRoseCurryWeb.jpg/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		mattImage: file(absolutePath: {regex: "/MattBobbittWeb.jpg/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		ryanImage: file(absolutePath: {regex: "/RyanPatrick.jpg/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		staceyImage: file(absolutePath: {regex: "/stacey2.jpg/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		jimImage: file(absolutePath: {regex: "/JimWeb.jpg/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		soniaImage: file(absolutePath: {regex: "/Sonia Reps_BW.png/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		vlmImage: file(absolutePath: {regex: "/VLMLOGO-Black.png/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		hapaImage: file(absolutePath: {regex: "/Hapa_BW.png/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		soniaColorImage: file(absolutePath: {regex: "/Sonia Reps_Color.png/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		vlmColorImage: file(absolutePath: {regex: "/VLMLOGO-BlackandLime.png/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
		hapaColorImage: file(absolutePath: {regex: "/Hapa_Color.png/"}) {
			id
			childImageSharp{
				fluid(maxWidth: 1240 ) {
					...GatsbyImageSharpFluid_tracedSVG
				}
			}
		}
	}
`